<template>

  <div>
<b-modal
      v-model="modal"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      title="Ajouter un commentaire"
    >
      <b-row class="mb-2">
        <h5>Modifier type d'user</h5>
      </b-row>
      <b-row v-if="user" class="mb-2">
        <b-col
          cols="4"
        >
          <b-form-group
            label="Adresse"
            label-for="adresse"
          >
            <v-select
              v-model="user.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              :reduce="val => val.value"
              :clearable="false"
              transition=""
              input-id="user-type"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            @click="updateUser()"
          >
            Enregistrer
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <Register
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      @add="list"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <!-- <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col> -->

          <!-- Search -->
          <b-col
            cols="12"
            md="12"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Ajouter un utilisateur</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="users"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(`${data.item.firstName} ${data.item.lastName}`)"
                :variant="`light-${resolveUserStatusVariant(data.item.type)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item._id } }"
              />
            </template>
            <b-link
              @click="openModal(data.item)"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.firstName }} {{ data.item.lastName }}
            </b-link>
            <small class="text-muted">@{{ data.item.firstName }}</small>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(type)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.type)}`"
            class="text-capitalize"
          >
            {{ data.item.type }}
          </b-badge>
        </template>

        <!-- Column: Status -->
        <template #cell(country)="data">
          <img
            style="width: 30px;"
            :src="require(`@/assets/images/flags/${data.item.country.toLowerCase()}.svg`)"
          >
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-users-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <!-- <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div> -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import store from '@/store'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
// import useUsersList from './useUsersList'
// import userStoreModule from '../userStoreModule'
import Register from '@components/user/Register.vue'

export default {
  components: {
    Register,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BModal,
    vSelect,
    // vSelect,
  },
  setup() {
    const isAddNewUserSidebarActive = ref(false)

    return {
      // Sidebar
      isAddNewUserSidebarActive,
    }
  },
  data() {
    return {
      users: [],
      tableColumns: [],
      avatarText,
      user: null,
      modal: false,
      typeOptions: [
        { label: 'Admin', value: 'admin' },
        { label: 'Manager', value: 'manager' },
        { label: 'Utilisateur', value: 'user' },
        { label: 'Externe', value: 'external' },
        { label: 'Logisticien', value: 'logistic' },
        { label: 'Vendeur', value: 'sales' },
      ],
      // eslint-disable-next-line global-require
      image: require('@/assets/images/portrait/small/avatar-s-25.jpg'),
    }
  },
  async mounted() {
    const tableColumns = [
      { key: 'user', sortable: true },
      { key: 'email', sortable: true },
      { key: 'type', sortable: true },
      { key: 'country', sortable: true },
    ]
    this.tableColumns = tableColumns
    await this.list()
  },
  methods: {
    openModal(user) {
      this.modal = true
      this.user = user
    },
    async updateUser() {
      try {
        await this.$http.post('/user/update-type', { type: this.user.type, email: this.user.email })
        this.modal = false
      } catch (err) {
        console.log(err)
      }
    },
    async list() {
      this.isAddNewUserSidebarActive = false
      const { users } = await this.$http.get('/user')
      this.users = users
    },
    resolveUserStatusVariant(type) {
      if (type === 'admin') return 'success'
      if (type === 'user') return 'secondary'
      return 'primary'
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
