<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ajouter un utilisateur
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Nom"
            rules="required"
          >
            <b-form-group
              label="Nom"
              label-for="firstName"
            >
              <b-form-input
                id="firstName"
                v-model="userData.firstName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Victor"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Prénom"
            rules="required|alpha-num"
          >
            <b-form-group
              label="Prénom"
              label-for="lastName"
            >
              <b-form-input
                id="lastName"
                v-model="userData.lastName"
                :state="getValidationState(validationContext)"
                placeholder="Loredo"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                placeholder="victor@kipli.com"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Mot de passe"
            rules="required"
          >
            <b-form-group
              label="Mot de passe"
              label-for="password"
            >
              <b-form-input
                id="password"
                v-model="userData.password"
                :state="getValidationState(validationContext)"
                placeholder="Mot de passe..."
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <validation-provider
            #default="validationContext"
            name="Pays"
            rules="required"
          >
            <b-form-group
              label="Pays"
              label-for="country"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :clearable="false"
                input-id="country"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User type -->
          <validation-provider
            #default="validationContext"
            name="Rôle"
            rules="required"
          >
            <b-form-group
              label="Rôle"
              label-for="user-type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-type"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            v-if="userData.type === 'logistic'"
            #default="validationContext"
          >
            <b-form-group
              label="Logisticien"
              label-for="logisticien"
            >
              <b-form-input
                id="logisticien"
                v-model="userData.logisticien"
                :state="getValidationState(validationContext)"
                placeholder="Dequecker"
                trim
              />
            </b-form-group>
          </validation-provider>

          <!-- Plan -->
          <!-- <validation-provider
            #default="validationContext"
            name="Plan"
            rules="required"
          >
            <b-form-group
              label="Plan"
              label-for="plan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.currentPlan"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="plan"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="register"
            >
              Ajouter
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      userData: {
        firstName: '',
        lastName: '',
        email: '',
        type: null,
        country: '',
        password: Math.random().toString(36).substring(2),
        logisticien: null,
      },
      typeOptions: [
        { label: 'Admin', value: 'admin' },
        { label: 'Manager', value: 'manager' },
        { label: 'Utilisateur', value: 'user' },
        { label: 'Externe', value: 'external' },
        { label: 'Logisticien', value: 'logistic' },
        { label: 'Vendeur', value: 'sales' },
      ],
      countries: ['France', 'Italie', 'Espagne', 'Allemagne'],
    }
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
  methods: {
    resetForm() {
      this.userData = {
        firstName: '',
        lastName: '',
        email: '',
        type: null,
        country: '',
        password: Math.random().toString(36).substring(2),
      }
    },
    async register() {
      try {
        this.$refs.refFormObserver.validate().then(async success => {
          if (!success) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Vous devez renseigner tous les champs.',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          } else {
            await this.$http.post('/user/register', this.userData)
            this.$emit('add')
          }
        })
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
